<span class="layout"
      [class]="inputClassName"
      [class.disabled]="disabled"
>
  <span
    *ngIf="!isReadonly"
    role="button"
    data-id="Select All Loaded Experiment"
    class="purple-v"
    [class]="inputClassName"
    [class.checked] = "state === 'All' || state === 'Partial'"
    [class.readonly] = "isReadonly"
    [smTooltip]="getCurrentError()"
    tooltipType='validation'
    [showTooltip]="showErrors"
  >
    <i class="al-icon sm al-color white"
       [class.al-ico-success]="state === 'All'"
       [class.spacer]="state === 'None'"
       [class.al-ico-minus]="state === 'Partial'"
    >
    </i>
  </span>
  <span
    *ngIf="label"
    class="checkbox-label ellipsis"
    [class]="readonlyClassName"
    [class.pointer]="isReadonly"
    [smTooltip]="label"
    smShowTooltipIfEllipsis
  >{{label}}
  </span>
</span>
